import Emitter from 'utils/emitter'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { GLOBAL_CONSTANTS, isTablet } from 'utils/constants'

gsap.registerPlugin(ScrollTrigger)

/**
 * Hero Image resize
 */
const SELECTORS = {
    COMPONENT: '.js-hero-resize',
    CONTAINER: '.js-exhibit-media-container',
    TRIGGER: '.js-hero-trigger'
}
export default class HeroResize {

    constructor(element) {
        // This can be made dynamic when needed currently no use case
        this.element = element
        this.trigger = SELECTORS.TRIGGER
        this.container = SELECTORS.CONTAINER
        this.tl = null
        this.width = '90vw'
        // Larger than 1024.
        if (!isTablet()) {
            this.initialize()
        }
    }
    /**
     * @desc Init.
     */
    initialize() {
        this.createController()
        this.reportWindowSize()
    }

    /**
     * create scroll trigger
     */
    createController() {
        this.tl =
            gsap.to(this.container, {
                scrollTrigger: {
                    trigger: this.trigger,
                    start: 'top',
                    toggleActions: 'play reset none none',
                    markers: false,
                    scrub: true,
                    invalidateOnRefresh: true,
                    onEnter: () => {
                        this.element.classList.add(GLOBAL_CONSTANTS.CLASSES.ACTIVE)
                    },
                    onLeaveBack: () => {
                        this.element.classList.remove(GLOBAL_CONSTANTS.CLASSES.ACTIVE)
                    }
                },
                width: this.width,
                duration: 3
            })
    }

    /**
     * Fire resize event listener
     */
    reportWindowSize() {
        Emitter.on(GLOBAL_CONSTANTS.EVENTS.RESIZE, this.restartScrollTrigger.bind(this))
    }

    /**
     * Kill and restart if window gets resized
     */
    restartScrollTrigger() {
        if (this.tl !== undefined) {
            this.tl.kill(true)
            gsap.set(this.container, {
                clearProps: true
            })
            this.createController()
        }
    }
}

/**
 * @desc HeroResizeComponent definition used in module-loader
 */
export const HeroResizeComponent = {
    'name': 'HeroResize',
    'class': SELECTORS.COMPONENT,
    'Source': HeroResize
}
